<template>
	<v-container fluid style="width: 80%">
		<v-layout class="my-2">
			<!-- <v-flex> -->
			<!-- <h3 class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue">Leave</h3> -->
			<!-- </v-flex> -->
			<v-flex class="text-right">
				<v-btn depressed tile class="custom-grey-border custom-bold-button" v-on:click="goBack">
					<v-icon small left>mdi-keyboard-backspace</v-icon>
					Back
				</v-btn>
			</v-flex>
		</v-layout>
		<perfect-scrollbar
			:options="{ suppressScrollX: true }"
			class="scroll custom-box-top-inner-shadow"
			style="max-height: 85vh; position: relative"
		>
			<v-card flat class="custom-setting-height custom-grey-border remove-border-radius">
				<v-card-title class="headline grey lighten-4">
					<v-row>
						<v-col md="6" class="py-0 my-auto">
							<h3 class="font-weight-700 custom-headline color-custom-blue" style="margin: 3.5px 0px">
								Email Notifications
							</h3>
							<small style="font-size: 12px" class="text-none">
								<v-icon size="16">mdi-progress-question</v-icon> Group wise email notifications.
							</small>
						</v-col>
						<template>
							<v-col md="6" class="text-right py-0">
								<v-tooltip top>
									<template #activator="{ on, attrs }">
										<v-btn
											color="red darken-4 mr-3"
											class="white--text my-auto"
											depressed
											tile
											@click="create_notifyGroup_dialog = true"
											v-on="on"
											v-bind="attrs"
											min-height="26px"
										>
											<v-icon left>mdi-plus</v-icon>
											Create Group
										</v-btn>
									</template>
									<span>Create Email notification group</span>
								</v-tooltip>

								<!-- <v-btn
									color="blue darken-4"
									class="white--text my-auto"
									depressed
									tile
									@click="updateOrcreate"
									:disabled="pageLoading || !formValid"
								>
									<span class="font-size-16 font-weight-600">Save</span>
								</v-btn> -->
							</v-col>
						</template>
					</v-row>
				</v-card-title>
				<v-card-text class="p-6 pt-2 font-size-16">
					<v-row class="mb-2" style="background-color: #f3f6f9">
						<v-col md="7">
							<div style="color: #24326d !important" class="mb-0 fw-600 h6">Group Name</div>
						</v-col>
						<v-col md="4">
							<div style="color: #24326d !important" class="mb-0 fw-600 h6">Total Emails</div>
						</v-col>
						<v-col md="1" class="text-center">
							<div style="color: #24326d !important" class="mb-0 fw-600 h6">Action</div>
						</v-col>
					</v-row>

					<template v-if="pageLoading">
						<div class="mx-20 my-40 text-center">
							<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
						</div>
					</template>

					<template v-else-if="pageLoading == false && emailNotificationGroups.length">
						<template v-for="(group, index) in emailNotificationGroups">
							<v-hover :key="group.group_name + '_' + index" v-slot="{ hover }">
								<v-row :class="{ 'on-hover': hover }" @click="openSingleDetail(group)">
									<v-col md="7">
										<div class="ml-3">
											<!-- <span class="mr-2">{{ index + 1 }}</span> -->
											<span class="h6">{{ group.group_name }}</span>
										</div>
									</v-col>
									<v-col md="4">
										<div class="ml-3">
											<!-- <span class="mr-2">{{ index + 1 }}</span> -->
											<v-chip label outlined color="#393939">
												<span class="h6 mb-0">{{ group.emails.length }}</span>
											</v-chip>
										</div>
									</v-col>
									<v-col md="1">
										<div class="d-flex justify-content-between">
											<v-tooltip top>
												<template #activator="{ on, attrs }">
													<v-icon
														color="primary"
														class="cursor-pointer"
														@click.stop="updateSingleGroup(group, index)"
														v-on="on"
														v-bind="attrs"
														>mdi-pencil</v-icon
													>
												</template>
												<span>Edit {{ group.group_name }}</span>
											</v-tooltip>

											<v-tooltip top>
												<template #activator="{ on, attrs }">
													<v-icon
														color="red"
														class="cursor-pointer"
														@click.stop="deleteSingleGroup(group, index)"
														v-on="on"
														size="27"
														v-bind="attrs"
														>mdi-delete</v-icon
													>
												</template>
												<span>Delete {{ group.group_name }} group</span>
											</v-tooltip>
										</div>
									</v-col>
									<v-col
										md="12"
										v-if="emailNotificationGroups.length > 1 && emailNotificationGroups.length - 1 != index"
									>
										<v-divider class="my-1"></v-divider>
									</v-col>
								</v-row>
							</v-hover>
						</template>
					</template>
					<template
						v-else-if="
							pageLoading == false &&
							(!emailNotificationGroups.length || emailNotificationGroups.length == 0)
						"
					>
						<v-row>
							<v-col md="12">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There <span class="text-lowercase">are no group at the moment.</span>
								</p>
							</v-col>
						</v-row>
					</template>
				</v-card-text>
			</v-card>
		</perfect-scrollbar>

		<!-- Create Email Notification Group Form -->
		<Dialog
			:dialog="create_notifyGroup_dialog"
			:dialog-width="800"
			@close="create_notifyGroup_dialog = false"
		>
			<template v-slot:title
				>{{ getValue(newGroup, "id") ? "Update" : "Create" }} Notifications Group</template
			>

			<template v-slot:body>
				<v-form
					ref="email_notifications"
					v-model.trim="formValid"
					@submit="updateNcreateNewNotificationGroup"
					lazy-validation
				>
					<!-- v-on:submit.stop.prevent="createOrUpdateCustomer" -->
					<v-row class="mb-3 px-4">
						<v-col lg="3" cols="12" class="my-auto py-0">
							<label class="input-label required">Group Name</label>
						</v-col>
						<v-col lg="9" class="my-auto pr-2 py-0">
							<v-text-field
								hide-details
								outlined
								:loading="pageLoading"
								:disabled="pageLoading"
								class="currency-input"
								placeholder="Group Name"
								v-model="newGroup.group_name"
								:rules="[vrules.required(newGroup.group_name, 'Group Name')]"
								:class="{
									required: !newGroup.group_name,
								}"
							></v-text-field>
						</v-col>
					</v-row>

					<v-row class="mb-3 px-4">
						<v-col lg="3" cols="12" class="my-auto py-0">
							<label class="input-label required">Emails</label>
						</v-col>
						<v-col lg="9" class="my-auto d-flex align-center justify-content-between py-0">
							<!-- <v-text-field
								hide-details
								outlined
								class="currency-input"
								placeholder="Group Name"
								v-model="newGroup.group_name"
								:rules="[vrules.required(newGroup.group_name, 'Group Name')]"
								:class="{
									required: !newGroup.group_name,
								}"
							></v-text-field> -->

							<em class="text-muted">Max 10 emails can be added.</em>

							<!-- <v-btn
								tile
								depressed
								:disabled="pageLoading || newGroup.emails.length > 9"
								color="blue darken-4"
								@click="addEmail"
								:loading="pageLoading"
								class="white--text fw-500"
							>
								<v-icon left>mdi-plus</v-icon> Add Email
							</v-btn> -->
						</v-col>
					</v-row>

					<v-row class="mb-3 px-4" v-for="(item, index) in newGroup.emails" :key="'_' + index">
						<v-col md="3" cols="12" class="my-auto py-0"> </v-col>
						<v-col md="8" class="my-auto d-flex align-center justify-content-between py-0">
							<v-text-field
								hide-details
								outlined
								:loading="pageLoading"
								:disabled="pageLoading"
								class="currency-input"
								:placeholder="`Email ${index + 1}`"
								v-model="newGroup.emails[index]"
								:rules="[vrules.validEmail(newGroup.emails[index], `Email ${index + 1}`)]"
							></v-text-field>
						</v-col>
						<v-col md="1" class="my-auto pl-0 d-flex align-center justify-content-between py-0">
							<v-icon color="red" :disabled="newGroup.emails.length == 1" @click="deleteEmail(index)">
								mdi-delete
							</v-icon>
							<v-icon
								v-if="index == newGroup.emails.length - 1"
								color="blue darken-4"
								class="ml-2"
								:style="{
									border: `1px solid ${newGroup.emails.length > 9 ? '#00000042' : '#0d47a1'}`,
									borderRadius: '50%',
								}"
								@click="addEmail"
								:disabled="pageLoading || newGroup.emails.length > 9"
							>
								mdi-plus
							</v-icon>
						</v-col>
						<!-- <v-col lg="1" class="my-auto d-flex align-center justify-content-between py-0">
							
						</v-col> -->
					</v-row>
				</v-form>
			</template>

			<template v-slot:action>
				<v-btn
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="red darken-4"
					tile
					@click="updateNcreateNewNotificationGroup()"
					:disabled="pageLoading || !formValid"
				>
					{{ getValue(newGroup, "id") ? "Update" : "Create" }}
				</v-btn>
				<v-btn depressed tile :disabled="pageLoading" @click="closeNotificationGroup"> Close </v-btn>
			</template>
		</Dialog>

		<!-- Details of single group -->
		<Dialog
			:dialog="single_notifyGroup_dialog"
			:dialog-width="600"
			@close="single_notifyGroup_dialog = false"
		>
			<template #title><!-- {{ newGroup.group_name }} -->All Emails</template>

			<template #body>
				<div class="text-muted mb-4">
					<em>Emails related to notification group </em
					><span class="fw-600 primary--text">{{ newGroup.group_name }}</span
					>.
				</div>
				<div v-for="(email, index) in newGroup.emails" :key="email + '_' + index">
					<span class="fw-500 ml-2 primary--text">
						<a :href="`mailto:${email}`">{{ email }}</a>
					</span>
					<v-divider
						class="my-2"
						v-if="newGroup.emails.length > 1 && index != newGroup.emails.length - 1"
					></v-divider>
				</div>
			</template>

			<template #action>
				<v-btn depressed tile :disabled="pageLoading" @click="closeNotificationGroup"> Close </v-btn>
			</template>
		</Dialog>

		<!-- Delete Template -->
		<DeleteTemplate
			v-if="delete_notifyGroup_dialog"
			type="Email Notification Group"
			:delete-text="deleteGroup.group_name"
			v-on:success="successDeleteNotificationGroup"
			v-on:close="delete_notifyGroup_dialog = false"
			:delete-dialog="delete_notifyGroup_dialog"
			:delete-url="`email-groups/${deleteGroup.id}`"
		>
		</DeleteTemplate>
	</v-container>
</template>

<script>
import { POST, GET, DELETE } from "@/core/services/store/request.module";
import { SET_ERROR /* , SET_MESSAGE */ } from "@/core/services/store/common.module";
/* import { map } from "lodash"; */
import Dialog from "@/view/components/Dialog";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import objectPath from "object-path";

export default {
	name: "leave-setting",
	data() {
		return {
			customerId: 0,
			formValid: true,
			pageLoading: false,
			leaveList: [
				{
					category: null,
					maxdays: null,
				},
			],
			create_notifyGroup_dialog: false,
			single_notifyGroup_dialog: false,
			delete_notifyGroup_dialog: false,
			deleteGroup: null,
			newGroup: {
				group_name: null,
				emails: [""],
			},
			emailNotificationGroups: [
				{
					group_name: "Customer",
					emails: [
						"abc@gmail.com",
						"def@gmail.com",
						"abc@gmail.com",
						"def@gmail.com",
						"krishnkumar180895@gmail.com",
					],
				},
				{ group_name: "Clients", emails: ["abc@gmail.com", "def@gmail.com"] },
				{ group_name: "Class", emails: ["abc@gmail.com", "def@gmail.com"] },
			],
		};
	},
	methods: {
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		addEmail() {
			this.newGroup.emails.push("");
		},
		deleteEmail(index) {
			if (index > -1) {
				this.newGroup.emails.splice(index, 1);
			}
		},
		openSingleDetail(group) {
			let Id = objectPath.get(group, "id");
			if (Id) {
				this.getSingleNotificationGroup(Id);
			} else {
				this.newGroup.group_name = group.group_name;
				this.newGroup.emails = group.emails;
			}

			this.single_notifyGroup_dialog = true;
		},
		deleteSingleGroup(group, index) {
			let Id = objectPath.get(group, "id");
			this.deleteGroup = group;
			if (Id) {
				console.log({ Id });
				this.delete_notifyGroup_dialog = true;
				/* this.delete_Group(Id); */
			} else {
				/* this.delete_notifyGroup_dialog = true; */
				this.emailNotificationGroups.splice(index, 1);
			}
		},
		delete_Group(id) {
			/* http://127.0.0.1:8000/api/v1/email-groups/2 */
			this.pageLoading = true;
			this.$store
				.dispatch(DELETE, {
					url: `email-groups/${id}`,
				})
				.then(() => {
					this.getEmailNotificationGroups();
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getSingleNotificationGroup(id) {
			this.pageLoading = true;
			this.$store
				.dispatch(GET, {
					url: `email-groups/${id}`,
				})
				.then((data) => {
					if (data.id) {
						this.newGroup = data;
						this.newGroup.group_name = data.group_name;
						this.newGroup.emails = [...data.emails];
					}
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateSingleGroup(group) {
			let parsedId = parseInt(objectPath.get(group, "id"));

			if (isNaN(parsedId)) {
				return false;
			}

			this.getSingleNotificationGroup(parsedId);
			this.create_notifyGroup_dialog = true;
		},
		closeNotificationGroup() {
			this.single_notifyGroup_dialog = false;
			this.create_notifyGroup_dialog = false;
			this.newGroup.group_name = null;
			this.newGroup.emails = [""];
			delete this.newGroup.id;
		},
		getEmailNotificationGroups() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET, {
					url: "email-groups",
				})
				.then((data) => {
					if (data) {
						this.emailNotificationGroups = data;
					}
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		successDeleteNotificationGroup() {
			this.deleteGroup = null;
			this.delete_notifyGroup_dialog = false;
			this.getEmailNotificationGroups();
		},
		updateNcreateNewNotificationGroup() {
			/* http://127.0.0.1:8000/api/v1/email-groups */

			let payload = {
				group_name: this.newGroup.group_name,
				emails: [...this.newGroup.emails.filter(Boolean)],
			};

			if (objectPath.get(this.newGroup, "id")) {
				payload["id"] = objectPath.get(this.newGroup, "id");
			}

			if (Boolean(payload.group_name) == false || Boolean(payload.group_name.trim()) == false) {
				this.$store.commit(SET_ERROR, [
					{ message: "Group name is required.", model: true, timeout: 2000 },
				]);
				return false;
			}

			if (
				payload.emails.length == 0 ||
				!payload.emails.length ||
				(payload.emails.length == 1 && Boolean(payload.emails[0]) == false)
			) {
				this.$store.commit(SET_ERROR, [
					{ message: "Atleast one email is required to create group.", model: true, timeout: 2000 },
				]);
				return false;
			}

			this.pageLoading = true;

			this.$store
				.dispatch(POST, {
					url: "email-groups",
					data: { ...payload },
				})
				.then(({ data }) => {
					if (data) {
						this.emailNotificationGroups = data;
						this.getEmailNotificationGroups();
						this.closeNotificationGroup();
					}
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		/* createEmailNotificationGroup() {
			this.create_notifyGroup_dialog = true;
		}, */
		/* updateOrcreate() {
			const formErrors = this.validateForm(this.$refs["leave_category"]);

			this.$refs["leave_category"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["leave_category"].validate()) {
				return false;
			}
			this.$store
				.dispatch(POST, {
					url: "setting/leave",
					data: { categories: this.leaveList },
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Leave Assign successfully.",
						},
					]);
					this.getLeaveDays();
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateDays(days, typedata) {
			let decimals = days - Math.floor(days);
			if (decimals > 0) {
				typedata.maxdays = Math.floor(days) + 0.5;
			} else {
				typedata.maxdays = Math.floor(days);
			}
		},
		
		deleteCommon(categoriesid, index) {
			if (categoriesid) {
				this.deleteSettingLeave(categoriesid, index);
			} else {
				this.deleteLeaveDay(index);
			}
		},
		deleteLeaveDay(index) {
			if (this.leaveList.length > 1) {
				this.leaveList.splice(index, 1);
			}
		},
		deleteSettingLeave(categoriesid, index) {
			this.$store
				.dispatch(DELETE, {
					url: "setting/leave/" + categoriesid,
				})
				.then(() => {
					if (this.leaveList.length > 0) {
						this.leaveList.splice(index, 1);
					}
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Deleted ! Leave successfully.",
						},
					]);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getLeaveDays() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET, {
					url: "setting/leave",
				})
				.then((data) => {
					this.leaveList = data;
					this.leaveList = map(this.leaveList, (row) => {
						return { ...row, category: row.category.replace("-", " ") };
					});
				})
				.catch((error) => {
					this.logError(error);
					this.goBack();
				})
				.finally(() => {
					this.pageLoading = false;
				});
		}, */
	},
	/* getStaffSetting() {

        this.$store
          .dispatch(GET, { url: "setting/engineer" })
          .then(({ data }) => {
            this.setting = data;
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.pageLoading = false;
          });
      },
      updateStaffSetting() {

        this.pageLoading = true;
        this.$store
          .dispatch(PATCH, { url: "setting/engineer", data: this.setting })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.pageLoading = false;
          });
      },*/
	mounted() {
		/* this.getLeaveDays(); */
		this.getEmailNotificationGroups();
	},
	watch: {},
	components: {
		Dialog,
		DeleteTemplate,
	},
};
</script>
